import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AppGridContainer from "@crema/components/AppGridContainer";
import AppAnimate from "@crema/components/AppAnimate";
import GeneralStats from "modules/dashboards/Academy/GeneralStats";
import AppComponentCard from "@crema/components/AppComponentCard";
import AppHeader from "./AppHeader";
import { format, addMonths, startOfToday } from "date-fns";
import VerticalComposedChart from "./TableData";
import useProjectTaskStatus from "apis/project/getProjectTaskStatus";
import { getFormattedDate } from "@crema/helpers/DateHelper";


const ProjectStatus = () => {
  const [fromDate, setFromDate] = useState(format(addMonths(startOfToday(), -3), 'yyyy-MM-dd'));
  const [toDate, setToDate] = useState(format(new Date(), 'yyyy-MM-dd')); 
  

  const { projectTaskStatus, loading, error,fetchProjectTaskStatus }:any = useProjectTaskStatus(fromDate, toDate);

  useEffect(() => {
    fetchProjectTaskStatus()
  }, []);


  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <AppGridContainer>
        <Grid item xs={12} lg={6}>
          <Box
            component="h6"
            sx={{
              mb: { xs: 4, xl: 6 },
              fontSize: 20,
              fontWeight: "fontWeightBold",
            }}
          >
            <Typography variant="h3" fontWeight={600}>
              Project Status
            </Typography>
          </Box>
        </Grid>
        <AppHeader
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          fetchProjectTaskStatus={fetchProjectTaskStatus}
        />
        {projectTaskStatus?.length == 0 ?
        
        <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                fontSize: 20,
                fontWeight: 'fontWeightBold',
                color: 'grey.600'
              }}
            >
              No records found between the given dates
            </Box>
          </Grid>

        :
        
        (projectTaskStatus?.map((project) => (
          <Grid key={project.id} item xs={12} sm={6}>
            <AppComponentCard 
              title={project.project_name}
              description= {`Start Date: ${getFormattedDate(project.start_date)} \u00A0\u00A0\u00A0 Deadline: ${getFormattedDate(project.dead_line)}`}
              component={() => <VerticalComposedChart tasks={project.tasks} />}
            />
          </Grid>
        )))}
      </AppGridContainer>
    </AppAnimate>
  );
};

export default ProjectStatus;
