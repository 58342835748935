import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useThemeContext } from '@crema/context/AppContextProvider/ThemeContextProvider';
import AppAnimate from '@crema/components/AppAnimate';

const VerticalComposedChart = ({ tasks }) => {
  const { theme } = useThemeContext();

  // Function to determine bar fill color based on the percentage
  const getBarColor = (value) => {
    if (value === 0) {
      return 'gray';
    } else if (value >= 100) {
      return 'green';
    } else {
      return 'orange';
    }
  };

  // Transform data into an array of objects expected by Bar
  const chartData = tasks.map((entry) => ({
    name: entry.task_name,
    percentage: entry.percentageCompleted,
    fill: getBarColor(entry.percentageCompleted),
    employeeName: entry.Employee ? entry.Employee.employee_name : '', // Ensure entry.Employee exists before accessing employee_name
  }));

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          layout="vertical"
          data={chartData} // Use chartData, which is an array of objects
          margin={{  }}
        >
          <XAxis type="number" 
            tick={{ fontSize: 12, fill: '#fff' }} // Set the font color to white
          />
          <YAxis
            dataKey="name"
            type="category"
            interval={0}
            tick={{ fontSize: 12, fill: '#fff' }} // Set the font color to white
            width={80} // Set a wider width for Y-axis to accommodate task names
          />
          <Tooltip
            cursor={{ fill: 'transparent' }} // Hide cursor on tooltip hover
            content={({ payload }) => {
              if (payload && payload.length > 0) {
                const { payload: firstPayload } = payload[0];
                return (
                  <div style={{ backgroundColor: 'white',color:'#000', padding: '10px', border: '1px solid #ccc' }}>
                    <p><strong>Task Name:</strong> {firstPayload.name}</p>
                    <p><strong>Employee Name:</strong> {firstPayload.employeeName}</p>
                    <p><strong>Percentage Completed:</strong> {firstPayload.percentage}%</p>
                  </div>
                );
              }
              return null;
            }}
          />
          <CartesianGrid stroke="#F5F5F5" />

          {/* Render Bars dynamically based on data */}
          <Bar
            dataKey="percentage"
            barSize={25}
            fill="fill" // Specify the data key for fill color
            label={{ position: 'right', offset: 5 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </AppAnimate>
  );
};

export default VerticalComposedChart;
