import { useState, useEffect } from "react";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { urls } from "apis/url";

const useProjectTaskStatus = (startDate,deadLine) => {
  const [projectTaskStatus, setProjectTaskStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

    const fetchProjectTaskStatus = async () => {
      setLoading(true);
      try {
        const response = await jwtAxios.get(urls.fetchProjectTaskStatus, {
          params: {start_date:startDate,dead_line:deadLine  },
        });
        setProjectTaskStatus(response.data);
      } catch (error) {
        setError(error.message);
      }
      setLoading(false);
    };


  return {projectTaskStatus, loading, error ,fetchProjectTaskStatus};
};

export default useProjectTaskStatus;
