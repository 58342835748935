import React from 'react';
import { useAuthMethod, useAuthUser } from '@crema/hooks/AuthHooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import PersonalInfoForm from './PersonalInfoForm';
import { Box } from '@mui/material';
import { updateUserData } from 'apis/users/updateUserData';

const validationSchema = yup.object({
  companyName: yup.string().max(50, 'Company Name must be at most 50 characters').required('Company Name is required'),
  companyAddress: yup.string().max(75, 'Company Address must be at most 75 characters').required('Company Address is required'),
  adminName: yup.string().max(25, 'Administrator Name must be at most 25 characters').required('Administrator Name is required'),
  phoneNumber: yup.string().matches(/^\d{1,10}$/, 'Phone Number must be a number with maximum 10 digits').required('Phone Number is required'),
  fax: yup.string(), // Assuming no specific validation for Fax
});
const PersonalInfo = () => {
  const { user } = useAuthUser();
  const { updateUser } = useAuthMethod();


  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Formik
        validateOnBlur={true}
        initialValues={{
          ...user,
        }}
        validationSchema={validationSchema}
        onSubmit={async(data, { setSubmitting }) => {
          setSubmitting(true);
          console.log('data: ', data);
          const response = await updateUserData(data.id,data)
          updateUser(response)
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue }) => {
          return (
            <PersonalInfoForm values={values} setFieldValue={setFieldValue} />
          );
        }}
      </Formik>
    </Box>
  );
};

export default PersonalInfo;
