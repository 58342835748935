import React from 'react';
import { Box, Typography } from '@mui/material';
import IntlMessages from '@crema/helpers/IntlMessages';
import { Fonts } from '@crema/constants/AppEnums';
import ChangePasswordForm from './ChangePasswordForm';
import { Formik } from 'formik';
import * as yup from 'yup';
import { updateUserPassword } from 'apis/users/updateUserPassword';
import { useAuthUser } from '@crema/hooks/AuthHooks';

const validationSchema = yup.object({ 
  oldPassword: yup
    .string()
    .required('No password provided.'),
  newPassword: yup
    .string()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
  retypeNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});



const ChangePassword = () => {
  const { user } = useAuthUser();

  return (
    <Box
      sx={{
        position: 'relative',
        maxWidth: 550,
      }}
    >
      <Typography
        component='h3'
        sx={{
          fontSize: 16,
          fontWeight: Fonts.BOLD,
          mb: { xs: 3, lg: 5 },
        }}
      >
        <IntlMessages id='common.changePassword' />
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={true}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          retypeNewPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (data, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          try {
            await updateUserPassword(user.id,{
              currentPassword: data.oldPassword,
              newPassword: data.newPassword,
            });
            resetForm();
          } catch (error) {
            console.error('Error updating password: ', error);
            // Handle error notification here
          }
          setSubmitting(false);
        }}
      >
        {() => <ChangePasswordForm />}
      </Formik>
    </Box>
  );
};

export default ChangePassword;
