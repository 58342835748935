import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AppDatePicker from "@crema/components/AppFormComponents/AppDateFieldRegular";
import { Button, FormControl, InputLabel, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import { differenceInMonths, addMonths } from "date-fns"; // Import addMonths function

type Props = {
  fromDate: any;
  setFromDate: (date: any) => void;
  toDate: any;
  setToDate: (date: any) => void;
  fetchProjectTaskStatus?: any;
};

const AppHeader = (props: Props) => {
  const { fromDate, toDate, setFromDate, setToDate, fetchProjectTaskStatus } = props;

  const handleValidate = () => {
    const maxDifferenceInMonths = 3;
    const dateDifference = differenceInMonths(new Date(toDate), new Date(fromDate));

    if (dateDifference <= maxDifferenceInMonths) {
      fetchProjectTaskStatus();
    } else {
      toast.error("The date difference should not exceed 3 months.");
    }
  };

  const handleToDateChange = (date: any) => {
    setToDate(date); // Set toDate
    const fromDateThreeMonthsAgo = addMonths(new Date(date), -3); // Calculate fromDate 3 months before toDate
    setFromDate(fromDateThreeMonthsAgo); // Set fromDate
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                justifyContent: { xs: "flex-end", md: "flex-start" },
              }}
            >
              <FormControl
                sx={{ 
                  mr: { md: 2 }, 
                  mb: { xs: 2, md: 0 }, 
                  minWidth: '200px', 
                  maxWidth: '400px' // Setting minimum and maximum width
                }}
              >
                <InputLabel shrink htmlFor="to-date">
                  Deadline
                </InputLabel>
                <AppDatePicker
                  defaultValue={toDate}
                  onChange={(date) => handleToDateChange(date)}
                />
              </FormControl>
              <Button 
                variant="contained" 
                color="primary" 
                type="button"
                onClick={handleValidate}
                sx={{ minWidth: '100px', maxWidth: '200px' }} // Setting minimum and maximum width
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AppHeader;

AppHeader.defaultProps = {
  filterText: "",
};
