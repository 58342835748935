import React, { useEffect, useState } from 'react';
import { useUrlSearchParams } from 'use-url-search-params';
import AppContentView from '@crema/components/AppContentView';
import generateRoutes from '@crema/helpers/RouteGenerator';
import { Layouts } from '@crema/components/AppLayout';
import { useAuthUser } from '@crema/hooks/AuthHooks';
import {
  useLayoutActionsContext,
  useLayoutContext,
} from '@crema/context/AppContextProvider/LayoutContextProvider';
import { useSidebarActionsContext } from '@crema/context/AppContextProvider/SidebarContextProvider';
import {
  anonymousStructure,
  authorizedStructure,
  publicStructure,
} from '../AppRoutes';
import { useRoutes } from 'react-router-dom';
import routesConfig from '../AppRoutes/routeconfig';
import { CircularProgress } from '@mui/material';

const AppLayout = () => {
  const { navStyle } = useLayoutContext();
  const { user, isAuthenticated } = useAuthUser();
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAuthenticated !== undefined && user !== undefined) {
      setLoading(false);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string);
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [params, setSidebarBgImage, updateNavStyle, updateMenuStyle]);

  if (loading) {
    return<CircularProgress color='inherit' size={20} />

  }

  // Generate routes based on authenticated status and user role
  const generatedRoutes = generateRoutes({
    isAuthenticated: isAuthenticated || false,
    userRole: user?.role,
    publicStructure,
    authorizedStructure,
    anonymousStructure,
  });

  return (
    <>
      {isAuthenticated ? (
        <AppLayout routes={useRoutes(generatedRoutes)} routesConfig={routesConfig} />
      ) : (
        <AppContentView routes={useRoutes(generatedRoutes)} />
      )}
    </>
  );
};

export default AppLayout;
