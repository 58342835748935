
import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import logoSrc from "./AcesoftLogo.png";
import VerticalNavItem from './VerticalNavItem';
import { ListItemText } from '@mui/material';

const CompanyLogo: React.FC = () => {
  const theme = useTheme();

  return (
    <VerticalNavItem>
      <Box
        component="a" // Use Box as an anchor tag
        href="https://acesoft.ca/" // The URL to redirect to
        target="_blank" // Open the link in a new tab
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none', // Remove underline
          color: 'inherit', // Keep text color consistent
        }}
      >
        <img
          src={logoSrc}
          alt="Company Logo"
          style={{ height: 'auto', maxHeight: '30px', width: 'auto', maxWidth: '30px' }}
        />
        <ListItemText
          className='nav-item-content'
          primary="Developed By AceSoft"
          classes={{ primary: 'nav-item-text' }}
          sx={{
            marginLeft: theme.spacing(1),
            display: { sm: 'block' }, // Adjust visibility based on sidebar state
          }}
        />
      </Box>
    </VerticalNavItem>
  );
};

export default CompanyLogo;
