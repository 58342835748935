export const data = [
  {
    name: "Project 1",
    project_type:"type 1",
    start_date: "1-06-2024",
    deadline: "30-06-2024",
    customer: "David Wallace",
    task:[
      {
        name: "Admin",
        Employee :"john",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "CNC",
        Employee :"john",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Drilling",
        Employee :"tester1",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Grinding",
        Employee :"john",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Assembly",
        Employee :"john",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:80
      },
    ]
  },
  {
    name: "Project 2",
    project_type:"type 2",
    start_date: "25-06-2024",
    deadline: "25-08-2024",
    customer: "Dwight Shrute",
    task:[
      {
        name: "Admin",
        Employee :"tester1",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "CNC",
        Employee :"tester1",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Drilling",
        Employee :"tester2",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Grinding",
        Employee :"tester1",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:70
      },
      {
        name: "Assembly",
        Employee :"tester2",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:0
      },
    ]
  },
  {
    name: "Project 3",
    project_type:"type 3",
    start_date: "15-06-2024",
    deadline: "15-08-2024",
    customer: "Kevin Bacon",
    task:[
      {
        name: "Admin",
        ExpectedTime : 10,
        CompletionTime:12,
        Employee :"john",
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "CNC",
        Employee :"tester1",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Drilling",
        Employee :"tester2",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Grinding",
        Employee :"tester1",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:100
      },
      {
        name: "Assembly",
        Employee :"john",
        ExpectedTime : 10,
        CompletionTime:12,
        start_date: "10",
        deadline: "10",
        customer: "Test comment",
        percentage:80
      },
    ]
  },
];

export const employees=[
  {id:1,name:"john"},
  {id:2,name:"tester1"},
  {id:3,name:"tester2"}
]