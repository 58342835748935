import React from "react";
import Box from "@mui/material/Box";
import AppDatePicker from "@crema/components/AppFormComponents/AppDateFieldRegular";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useIntl } from "react-intl";

type Props = {
  fromDate: any;
  setFromDate: (date: any) => void;
  toDate: any;
  setToDate: (date: any) => void;
  employees: { id: number; name: string }[]; // Assuming each employee has an id and name
  setSelectedEmployee:any;
  selectedEmployee:any
};

const AppHeader = (props: Props) => {
  const { fromDate, setFromDate, toDate, setToDate, employees,setSelectedEmployee,selectedEmployee } = props;
  const { messages } = useIntl();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        
        <FormControl sx={{ marginRight: 2 }}>
          <InputLabel shrink htmlFor="from-date">
            From Date
          </InputLabel>
          <AppDatePicker
            defaultValue={fromDate}
            onChange={(date) => setFromDate(date)}
          />
        </FormControl>

        <FormControl>
          <InputLabel shrink htmlFor="to-date">
            To Date
          </InputLabel>
          <AppDatePicker
            defaultValue={toDate}
            onChange={(date) => setToDate(date)}
          />
        </FormControl>

        <FormControl variant="outlined" sx={{ width: '30%', ml: 4 }}>
          <InputLabel>Select Employee</InputLabel>
          <Select
            label="Select Employee"
            name="select_employee"
            defaultValue="All"
            onChange={(event) => setSelectedEmployee(event.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            {employees.map((employee) => (
              <MenuItem key={employee.id} value={employee.name}>
                {employee.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default AppHeader;

AppHeader.defaultProps = {
  filterText: "",
};
