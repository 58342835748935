import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import AppContextProvider from '@crema/context/AppContextProvider';
import AppThemeProvider from '@crema/context/AppThemeProvider';
import AppStyleProvider from '@crema/context/AppStyleProvider';
import AppLocaleProvider from '@crema/context/AppLocaleProvider';
import AuthRoutes from '@crema/components/AuthRoutes';
import AppLayout from '@crema/core/AppLayout';
import AppAuthProvider from '@crema/core/AppAuthProvider';
// import { store } from './toolkit/store';
// import '@crema/mockapi';
import './styles/index.css';
import InfoViewContextProvider from '@crema/context/AppContextProvider/InfoViewContextProvider';
import { ToastContainer } from 'react-toastify';

const App = () => (

      <AppThemeProvider>
          <AppLocaleProvider>
            <BrowserRouter>
            <InfoViewContextProvider>
              <AppAuthProvider>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                  <ToastContainer />
                </AuthRoutes>
              </AppAuthProvider>
              </InfoViewContextProvider>
            </BrowserRouter>
          </AppLocaleProvider>
      </AppThemeProvider>


);

export default App;
