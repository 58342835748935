import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useThemeContext } from '@crema/context/AppContextProvider/ThemeContextProvider';



const PositiveAndNegativeBarChart = ({data}) => {  
  const { theme } = useThemeContext();
  return (
    <ResponsiveContainer width='100%' height={200}>
      <BarChart
        data={data}
        margin={{ top: 10, right: 0, left: -25, bottom: 0 }}
      >
        <XAxis dataKey='name' />
        <YAxis /> 
        <CartesianGrid strokeDasharray='3 3' />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke='#808080' />
        <Bar dataKey='CompletionTime' fill={theme.palette.primary.main} />
        <Bar dataKey='ExpectedTime' fill={theme.palette.secondary.main} />
        <Bar dataKey='TimeDifference' fill="#808080" />
      </BarChart> 
    </ResponsiveContainer>
 ); 
};

export default PositiveAndNegativeBarChart;
