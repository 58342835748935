import {
  Fonts,
  FooterType,
  HeaderType,
  LayoutDirection,
  LayoutType,
  MenuStyle,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from './AppEnums';

export const textLight = {
  primary: 'rgb(17, 24, 39)',
  secondary: 'rgb(107, 114, 128)',
  disabled: 'rgb(149, 156, 169)',
};

export const textDark = {
  primary: 'rgb(255,255,255)',
  secondary: 'rgb(229, 231, 235)',
  disabled: 'rgb(156, 163, 175)',
};

export const backgroundDark = {
  paper: '#2B3137',
  default: '#1F2527',
};

export const backgroundLight = {
  paper: '#FFFFFF',
  default: '#F4F7FE',
};

const cardRadius = ThemeStyleRadius.STANDARD;


export const defaultTheme: any = {
  theme: {
    spacing: 4,
    cardRadius: cardRadius,
    direction: LayoutDirection.LTR, // ltr, rtl
    palette: {
      mode: ThemeMode.DARK, // Set the mode to DARK
      background: {
        paper: '#424242', // Dark paper color
        default: '#303030', // Dark background color
      },
      primary: {
        main: '#90caf9', // Lighter blue for primary elements
        contrastText: '#fff', // White text for contrast
      },
      secondary: {
        main: '#f48fb1', // Lighter pink for secondary elements
      },
      success: {
        main: '#66bb6a', // Green for success
        light: '#dcedc8', // Light green for success background
      },
      warning: {
        main: '#ffa726', // Orange for warning
        light: '#ffcc80', // Light orange for warning background
      },
      text: {
        primary: '#ffffff', // White primary text
        secondary: '#b0bec5', // Light grey for secondary text
        disabled: '#757575', // Grey for disabled text
      },
      gray: {
        50: '#fafafa',
        100: '#f5f5f5',
        200: '#eeeeee',
        300: '#e0e0e0',
        400: '#bdbdbd',
        500: '#9e9e9e',
        600: '#757575',
        700: '#616161',
        800: '#424242',
        900: '#212121',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
    },
    status: {
      danger: '#ff5722', // Red for danger status
    },
    divider: 'rgba(255, 255, 255, 0.12)', // Light divider for dark mode
    typography: {
      fontFamily: ['Be Vietnam', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeight: 400,
      h1: {
        fontSize: 22,
        fontWeight: 600,
        color: '#ffffff', // White text for headers
      },
      h2: {
        fontSize: 20,
        fontWeight: 500,
        color: '#ffffff',
      },
      h3: {
        fontSize: 18,
        fontWeight: 500,
        color: '#ffffff',
      },
      h4: {
        fontSize: 16,
        fontWeight: 500,
        color: '#ffffff',
      },
      h5: {
        fontSize: 14,
        fontWeight: 500,
        color: '#ffffff',
      },
      h6: {
        fontSize: 12,
        fontWeight: 500,
        color: '#ffffff',
      },
      subtitle1: {
        fontSize: 14,
        color: '#b0bec5', // Light grey for subtitles
      },
      subtitle2: {
        fontSize: 16,
        color: '#b0bec5',
      },
      body1: {
        fontSize: 14,
        color: '#e0e0e0', // Slightly darker grey for body text
      },
      body2: {
        fontSize: 12,
        color: '#e0e0e0',
      },
    },
    components: {
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            color: '#ffffff', // White text on toggle buttons
          },
        },
      },
      MuiCardLg: {
        styleOverrides: {
          root: {
            borderRadius:
              cardRadius === ThemeStyleRadius.STANDARD
                ? ThemeStyleRadius.STANDARD
                : ThemeStyleRadius.MODERN + 20,
            backgroundColor: '#424242', // Dark card background
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            boxShadow: '0px 10px 10px 4px rgba(0, 0, 0, 0.5)', // Darker shadow
            backgroundColor: '#424242', // Dark card background
            '& .MuiCardContent-root:last-of-type': {
              paddingBottom: 16,
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            textTransform: 'capitalize',
            color: '#ffffff', // White text for buttons
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            backgroundColor: '#424242', // Dark background for input fields
            color: '#ffffff', // White text for input fields
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius / 2,
            backgroundColor: '#424242', // Dark background for select fields
            color: '#ffffff', // White text for select fields
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            padding: 9,
            color: '#ffffff', // White icons
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            color: '#90caf9', // Primary color for links
          },
        },
      },
    },
  },
};


export interface SidebarData {
  sidebarBgColor: string;
  sidebarTextColor: string;
  sidebarHeaderColor: string;
  sidebarMenuSelectedBgColor: string;
  sidebarMenuSelectedTextColor: string;
  mode: string;
}

export const DarkSidebar: SidebarData = {
  sidebarBgColor: '#313541',
  sidebarTextColor: '#fff',
  sidebarHeaderColor: '#313541',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.DARK,
};

export const NewSidebar: SidebarData = {
  sidebarBgColor: '#313541',
  sidebarTextColor: '#fff',
  sidebarHeaderColor: '#639F52',
  sidebarMenuSelectedBgColor: '#639F52',
  sidebarMenuSelectedTextColor: '#fff',
  mode: ThemeMode.DARK,
};
export const LightSidebar: SidebarData = {
  sidebarBgColor: '#fff',
  sidebarTextColor: 'rgba(0, 0, 0, 0.60)',
  sidebarHeaderColor: '#fff',
  sidebarMenuSelectedBgColor: '#F4F7FE',
  sidebarMenuSelectedTextColor: 'rgba(0, 0, 0, 0.87)',
  mode: ThemeMode.LIGHT,
};
const defaultConfig = {
  sidebar: {
    borderColor: '#757575',
    menuStyle: MenuStyle.DEFAULT,
    isSidebarBgImage: false,
    sidebarBgImage: 1,
    colorSet: NewSidebar,
  },
  themeStyle: ThemeStyle.STANDARD,
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.HEADER_USER_MINI,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.FIXED,
  rtAnim: RouteTransition.NONE,
  footer: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
  rtlLocale: ['ar'],
};
export default defaultConfig;
