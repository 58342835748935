import React from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

/**
 * Generates dynamic routes based on the provided structure.
 * @param {Object} structure - The object that defines the routes.
 * @param {boolean} structure.isAuthenticated - Indicates if the user is authenticated.
 * @param {object} [structure.anonymousStructure] - Routes available for all users.
 * @param {object} [structure.authorizedStructure] - Routes available only for authenticated users.
 * @param {object} [structure.publicStructure] - Routes available only for unauthenticated users.
 * @returns {Array} - Array of generated routes.
 */
const generateRoutes = (structure: any) => {
  const {
    isAuthenticated = false,
    anonymousStructure = {},
    authorizedStructure = {},
    publicStructure = {},
  } = structure || {};

  console.log("Authenticated:", isAuthenticated);
  console.log("Anonymous Structure:", anonymousStructure);
  console.log("Authorized Structure:", authorizedStructure);
  console.log("Public Structure:", publicStructure);

  const dynamicRoutes: RouteObject[] = [];

  if (anonymousStructure.routes) {
    dynamicRoutes.push(...routesGenerator(isAuthenticated, anonymousStructure, 'anonymous'));
  }

  if (authorizedStructure.routes) {
    dynamicRoutes.push(...routesGenerator(isAuthenticated, authorizedStructure, 'authorized'));
  }

  if (publicStructure.routes) {
    dynamicRoutes.push(...routesGenerator(isAuthenticated, publicStructure, 'public'));
  }

  return dynamicRoutes;
};

/**
 * Generates routes based on the provided route set and type.
 * @param {boolean} isAuthenticated - Indicates if the user is authenticated.
 * @param {object} routeSet - The set of routes to generate.
 * @param {string} type - The type of routes (anonymous, authorized, public).
 * @returns {Array} - Array of generated routes.
 */
const routesGenerator = (
  isAuthenticated = false,
  routeSet: any = {},
  type = 'anonymous',
): any => {
  console.log(`Generating routes for type: ${type}, isAuthenticated: ${isAuthenticated}`);

  const generatedRoutes: any[] = [];
  const { fallbackPath = '' } = routeSet || {};

  if (routeSet?.routes) {
    routeSet.routes.forEach((route: any) => {
      const {
        path = '',
        redirectPath = '',
        showRouteIf = true,
      } = route || {};

      if (!showRouteIf || !path) {
        console.log(`Skipping route: ${path}, showRouteIf: ${showRouteIf}`);
        return;
      }

      const isAnonymous = type === 'anonymous';
      const isAuthorized = type === 'authorized';
      const renderCondition = isAuthorized ? isAuthenticated : !isAuthenticated;

      if (isAnonymous || renderCondition) {
        const finalPath = Array.isArray(route.path) ? route.path : [route.path];
        finalPath.forEach((path: string) => {
          generatedRoutes.push({ ...route, path });
        });
      } else {
        const finalPath = Array.isArray(route.path) ? route.path : [route.path];
        finalPath.forEach((path: string) => {
          generatedRoutes.push({
            path,
            element: <Navigate to={redirectPath || fallbackPath} replace />,
          });
        });
      }
    });
  } else {
    console.log(`No routes found in ${type}Structure`);
  }

  return generatedRoutes;
};

export default generateRoutes;
