import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import AppGridContainer from "@crema/components/AppGridContainer";
import AppAnimate from "@crema/components/AppAnimate";
import { data as projectData, employees } from "./project_status_data"; // Assuming this imports your data array
import GeneralStats from "modules/dashboards/Academy/GeneralStats";
import AppComponentCard from "@crema/components/AppComponentCard";
import AppHeader from "./AppHeader";
import { format, addMonths, startOfToday } from "date-fns";
import VerticalComposedChart from "./TableData";
import { filterData, transformData } from "./functions";

const EmployeePerformance = () => {
  const [fromDate, setFromDate] = useState(format(addMonths(startOfToday(), -6), 'yyyy-MM-dd')); // Default to 6 months ago as string
  const [toDate, setToDate] = useState(format(new Date(), 'yyyy-MM-dd')); // Default to today as string
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("All");

  useEffect(() => {
    const transformedProjects = transformData(projectData, employees);
    const filteredProject = filterData(transformedProjects, fromDate, toDate);
    console.log(transformedProjects,filteredProject);
    
    setFilteredProjects(filteredProject);
  }, [fromDate, toDate]);

  // useEffect(() => {
  //   const filteredProject = filterData(projectData, fromDate, toDate);
  //   setFilteredProjects(filteredProject);
  // }, [fromDate, toDate, selectedEmployee]);

  return (
    <AppAnimate animation="transition.slideUpIn" delay={200}>
      <AppGridContainer>
        <Grid item xs={12} lg={6}>
          <Box
            component="h6"
            sx={{
              mb: { xs: 4, xl: 6 },
              fontSize: 20,
              fontWeight: "fontWeightBold",
            }}
          >
            <Typography variant="h3" fontWeight={600}>
              Employee Performance Graph
            </Typography>
          </Box>
        </Grid>
        <AppHeader
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          employees={employees}
          setSelectedEmployee={setSelectedEmployee}
          selectedEmployee={selectedEmployee}
        />
        {filteredProjects.map((project, index) => (
          <Grid key={index} item xs={12} sm={6}>
            <AppComponentCard
              title={project.name }
              description={project.tasks[0].Employee}
              component={() => <VerticalComposedChart data={project.tasks} />}
            />
          </Grid>
        ))}
      </AppGridContainer>
    </AppAnimate>
  );
};

export default EmployeePerformance;

// Note: Don't forget to add transformData function as described in Step 1 above this code.
