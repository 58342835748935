import React from "react";
import { Navigate } from "react-router-dom";
import { RoutePermittedRole } from "@crema/constants/AppEnums";
import ProjectStatus from "modules/apps/ProjectStatus";
import EmployeePerformance from "modules/apps/EmployeePerformance/ProjectStatus";


const Dashboard = React.lazy(
  () => import("../../../modules/dashboards/Home/index")
);

const Projects = React.lazy(
  () => import("../../../modules/apps/Projects/index")
);

const Employees = React.lazy(
  () => import("../../../modules/apps/Employees/index")
);

const ProjectTasks = React.lazy(
  () => import("../../../modules/apps/ProjectTasks/index")
);

const ProjectReports = React.lazy(
  () => import("../../../modules/apps/ProjectReports/index")
);

const EmployeeReports = React.lazy(
  () => import("../../../modules/apps/EmployeeReports/index")
);

export const appsConfig = [
  
  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/dashboard",
    element: <Dashboard />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/projects",
    element: <Projects />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/employees",
    element: <Employees />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/employee-performance",
    element: <EmployeePerformance/>,
  },

  {
    permittedRole:RoutePermittedRole.User,
    path: "/apps/projects/:projectID/tasks",
    element: <ProjectTasks />,
  },

  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/project-status",
    element: <ProjectStatus/>,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/project-reports",
    element: <ProjectReports />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: "/apps/employee-reports",
    element: <EmployeeReports />,
  },
];
