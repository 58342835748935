import { useAuthMethod } from "@crema/hooks/AuthHooks";
import jwtAxios from "@crema/services/auth/jwt-auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function updateUserData(id, data) {
    try {
    const response = await jwtAxios.post(`/update-admin-details/${id}`, data);
    toast.success('User data updated successfully');
    localStorage.setItem('userData', JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    console.error("Error updating user data:", error);
    toast.error('Something went wrong');
    throw error;
  }
}
