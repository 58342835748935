import React from "react";
import List from "@mui/material/List";
import NavVerticalGroup from "./VerticalNavGroup";
import VerticalItem from "./VerticalItem";
import { RouterConfigData } from "@crema/types/models/Apps";
import { Box } from "@mui/material";
import CompanyLogo from "./VerticalItem/comapnylogo";

type Props = {
  routesConfig: RouterConfigData[];
};

const VerticalNav = ({ routesConfig }: Props) => {
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 0,
        height:"90vh",
        overflowY: "auto", // Allows scrolling if content overflows
      }}
      component="div"
    >
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        {routesConfig.map((item: RouterConfigData) => (
          <React.Fragment key={item.id}>
            {item.type === "group" && <NavVerticalGroup item={item} level={0} />}
            {item.type === "item" && <VerticalItem item={item} level={0} />}
          </React.Fragment>
        ))}
      </Box>
      <Box sx={{ mt: 'auto', pb: 2,overflow:"hidden" }}>
        <CompanyLogo />
      </Box>
    </List>
  );
};

export default VerticalNav;
