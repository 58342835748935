export const filterData = (data, fromDate, toDate) => {
    return data.filter((project) => {
      const projectStartDate = parseDate(project.start_date);
      const projectEndDate = parseDate(project.deadline);
      console.log(projectEndDate,projectEndDate <= toDate,"to") 
      console.log(projectStartDate,projectStartDate >= fromDate,"from",fromDate)
      return (!fromDate || projectStartDate >= fromDate) && (!toDate || projectEndDate <= toDate);
    });
  };

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('-');
    return (`${year}-${month}-${day}`);
  };
  
  export const transformData = (data, employees) => {
    const transformedData = [];
  
    data.forEach((project) => {
      // Find unique employees who worked on the current project
      const projectEmployees = project.task.map(task => task.Employee);
      const uniqueEmployees = [...new Set(projectEmployees)];
  
      uniqueEmployees.forEach((employee) => {
        const employeeTasks = project.task.filter(task => task.Employee === employee);
        const allTasks = project.task.map(task => ({
          ...task,
          Employee: employee,
          ExpectedTime: task.Employee === employee ? task.ExpectedTime : 0,
          CompletionTime: task.Employee === employee ? task.CompletionTime : 0,
          TimeDifference: task.Employee === employee ? task.ExpectedTime -task.CompletionTime :0// Calculate the difference
        }));
  
        transformedData.push({
          ...project,
          tasks: allTasks,
          employee: employee
        });
      });
    });
  
    return transformedData;
  };
  