import jwtAxios from "@crema/services/auth/jwt-auth";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export async function updateUserPassword(id, data) {
  try {
    const response = await jwtAxios.post(`/update-user-password/${id}`, data);
    toast.success('User password updated successfully');
    return response.data;
  } catch (error) {
    // console.error("Error updating user password:", error.response.data.message);
    toast.error(error.response.data.message);
    throw error;
  }
}
